import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Keyboard, Navigation, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../misc/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../config";

export default function Brands() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // use state
  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("en");
  const [companyList, setCompanyList] = useState([]);

  // store
  const { companies } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { webSettings } = useSelector((state) => state.webSettings);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    // dispatch(getDosages());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
    }
  }, [activeLangFront]);

  // set Companies
  useEffect(() => {
    if (lang !== "" && companies && companies.length > 0) {
      setLoadingMain(true);
      let C = companies.find((c) => c.lang === lang);
      if (C && C.data) setCompanyList(C.data.filter((d) => d.isVisible));
      else setCompanyList([]);
      if (companies) {
        setTimeout(() => {
          setLoadingMain(false);
        }, 1000);
      }
    }
  }, [companies, lang]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {loadingMain ? (
        <LoadingBox w={"100%"} h={"200px"} />
      ) : (
        <Card sx={{ m: 1 }}>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              sx={{
                background: webSettings.siteColorTheme || "lightblue",
                fontSize: { xs: "1.8rem", sm: "2rem" },
                mb: 1,
                pb: 1,
              }}
            >
              {translateFront("Our Partners")}
            </Typography>
            {/* Product Card */}
            <Swiper
              slidesPerView={!smUp ? 1 : !mdUp ? 2 : 6}
              grabCursor
              modules={[Navigation, Scrollbar, Keyboard, Autoplay]}
              // keyboard={{ enabled: true }}
              spaceBetween={4}
              navigation
              // scrollbar
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
              className="deal"
              loop={{ loop: true }}
              autoplay={{ delay: 3000 }}
              style={{ margin: "0 10%" }}
            >
              {companyList.map((x, i) => (
                <SwiperSlide key={i}>
                  <Card>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 150,
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        src={
                          x.companyLogo.includes("http")
                            ? x.companyLogo
                            : PF + "adminPanel/companies/" + x.companyLogo
                        }
                        alt={x.companyName}
                        title={x.companyName}
                        style={{
                          // height: "200px",
                          width: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                    <CardContent>
                      <Typography variant="subtitle2" align="center">
                        {x.companyName}
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        align="center"
                        sx={{ background: "lightblue", borderRadius: "2.5rem" }}
                      >
                        Since 2001
                      </Typography> */}
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </CardContent>
        </Card>
      )}
    </>
  );
}
