import {
  Divider,
  Grid,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Pagination,
  Drawer,
  Tooltip,
  Alert,
  Button,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ProductSmall from "../components/product/ProductSmall";
// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CategoryList from "../components/ourMedicine/CategoryList";
import { useDispatch, useSelector } from "react-redux";
import medicine, {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  getViewCount,
} from "../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../components/misc/Loading";
import { getUnique } from "../hooks/getUnique";

export default function OurMedicines() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // UseState
  const [medLoading, setMedLoading] = useState(false);
  const [mobCat, setMobCat] = useState(false);
  const [view, setView] = useState(0);
  const [filter, setFilter] = useState("generic-brand");
  const [revertFilter, setRevertFilter] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [productViewStart, setProductViewStart] = useState(0);

  const [lang, setLang] = useState("en");

  const [medList, setMedList] = useState([]);
  const [filteredMedList, setFilteredMedList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const [catListLoading, setCatListLoading] = useState(true);
  const [catList, setCatList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [dosageList, setDosageList] = useState([]);

  // Filter
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);

  const [selectedDFList, setSelectedDFList] = useState([]);
  const [selectedBrandList, setSelectedBrandList] = useState([]);

  const [filterTags, setFilterTags] = useState({
    subcategory: [],
    dosage: [],
    brand: [],
  });

  // store
  const {
    medicines,
    medVariants,
    categories,
    companies,
    dosages,
    viewCountArr,
  } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Function
  const handleView = () => {
    if (view === 0) setView(1);
    else setView(0);
  };

  const handlePageChange = (event, value) => {
    setPageNum(value);
    setTimeout(() => {
      scroll();
    }, 250);
  };

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    // if (!(medicines && medicines.length > 0))
    dispatch(getMedicines());
    // if (!(categories && categories.length > 0))
    dispatch(getMedCategories());
    // if (!(companies && companies.length > 0))
    dispatch(getCompanies());
    // if (!(dosages && dosages.length > 0))
    dispatch(getDosages());
    // if (!(viewCountArr && viewCountArr.length > 0))
    dispatch(getViewCount());
    setTimeout(() => {
      scroll();
    }, 250);
  }, []);

  useEffect(() => {
    if (pageNum > 1) setProductViewStart((pageNum - 1) * 24);
    else setProductViewStart(0);
  }, [pageNum]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setCatListLoading(true);
      setLang(activeLangFront.langCode);
      setPageNum(1);
      setFilteredMedList([]);
      // setTimeout(() => {
      //   setCatListLoading(false);
      // }, 1000);
    }
  }, [activeLangFront]);

  // Set Medicine
  useEffect(() => {
    setMedLoading(true);
    setCatListLoading(true);
    let arr = [];
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        let arr1 = [];
        M.data.forEach((x) => {
          let obj = { ...x };
          const C = viewCountArr.find(
            (c) =>
              c.medicineIdEn ===
              (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
          );
          if (C) obj.viewCount = C.viewCount;
          else obj.viewCount = 0;
          arr1.push(obj);
        });

        arr = [...arr1].sort((a, b) =>
          a.genericBrand.toLowerCase().replace(/\s/g, "") >
          b.genericBrand.toLowerCase().replace(/\s/g, "")
            ? 1
            : -1
        );
        setFilter("generic-brand");
        setRevertFilter(false);
        setTotalPage(Math.ceil(M.data.length / 24));
      } else {
        setTotalPage(0);
      }
      setTimeout(() => {
        setMedLoading(false);
        setCatListLoading(false);
      }, 2000);
    }
    arr = arr.filter((m) => m.visible === true);
    setMedList(arr);
  }, [medicines, lang]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  // set Companies
  useEffect(() => {
    if (lang !== "" && companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C && C.data) {
        setCompanyList(C.data.filter((d) => d.isVisible));
      } else setCompanyList([]);
    }
  }, [companies, lang]);

  // get Dosage List
  useEffect(() => {
    let dosageArr = [];
    if (medVariants.length > 0 && medList.length > 0) {
      medList.map((x) => {
        if (x.variants.length > 0) {
          x.variants.map((y) => {
            let M = medVariants.find((m) => m.fixedVariantId === y.variantId);
            if (M) dosageArr.push(M);
            return 0;
          });
        }
        return 0;
      });
    }
    if (dosageArr.length > 0) {
      const unique = [
        ...new Map(
          dosageArr.map((item) => [item["dosageForm"], item])
        ).values(),
      ];
      setDosageList(unique);
    } else setDosageList([]);
  }, [medList, medVariants]);

  useEffect(() => {
    if (filter === "generic-brand") {
      const M = [...medList].sort((a, b) =>
        a.genericBrand.toLowerCase().trim() >
        b.genericBrand.toLowerCase().trim()
          ? 1
          : -1
      );
      setMedList(M);
    }
    if (filter === "brand-name") {
      const M = [...medList].sort((a, b) =>
        (a.originator.trim() || "z") > (b.originator.trim() || "y") ? 1 : -1
      );
      setMedList(M);
    }
    if (filter === "company-name") {
      const M = [...medList].sort((a, b) =>
        (a.companyId.trim() || "z").toLowerCase() >
        (b.companyId.trim() || "y").toLowerCase()
          ? 1
          : -1
      );
      setMedList(M);
    }
    if (filter === "popularity") {
      // const M = [...medList].sort((a, b) =>
      //   (a.companyId.trim() || "z").toLowerCase() >
      //   (b.companyId.trim() || "y").toLowerCase()
      //     ? 1
      //     : -1
      // );
      const M = [...medList].sort((a, b) =>
        a.viewCount < b.viewCount ? 1 : -1
      );
      setMedList(M);
    }
    setRevertFilter(false);
  }, [filter]);

  // Set Total Page
  useEffect(() => {
    if (filteredMedList.length > 0)
      setTotalPage(Math.ceil(filteredMedList.length / 24));
    else setTotalPage(Math.ceil(medList.length / 24));
  }, [filteredMedList, filteredMedList.length, medList.length]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Box
      sx={{
        maxWidth: !smUp ? "90vw" : "100%",
        margin: "auto",
      }}
    >
      <BreadcrumbsTemplate b3={translateFront("Our Medicine")} />
      <Divider />
      <Grid container spacing={0} justifyContent="center">
        {mdUp && (
          <Grid item sm={2.5} xl={2}>
            {/* Select Category */}
            {medLoading || (!medLoading && catListLoading) ? (
              <LoadingBox w={"100%"} h={700} />
            ) : (
              <CategoryList
                lang={lang}
                catList={catList}
                companyList={companyList}
                dosageList={dosageList}
                medList={medList}
                setMedList={setMedList}
                filteredMedList={filteredMedList}
                setFilteredMedList={setFilteredMedList}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedSubCat={selectedSubCat}
                setSelectedSubCat={setSelectedSubCat}
                selectedDFList={selectedDFList}
                setSelectedDFList={setSelectedDFList}
                selectedBrandList={selectedBrandList}
                setSelectedBrandList={setSelectedBrandList}
                filterTags={filterTags}
                setFilterTags={setFilterTags}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={9.5} xl={8}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={
              !mdUp && {
                position: "sticky",
                top: 47,
                background: "white",
                zIndex: 2,
                ml: 1,
              }
            }
          >
            {!mdUp && (
              // Select Mobile Category
              <>
                <Grid item xs={2.5} sm={2}>
                  {!smUp ? (
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={() => setMobCat(true)}
                    >
                      {translateFront("Filter")}
                    </Button>
                  ) : (
                    // <IconButton
                    //   onClick={() => setMobCat(true)}
                    //   color="error"
                    //   sx={{ border: "1px solid red", ml: 1 }}
                    // >
                    //   <AccountTreeIcon />
                    // </IconButton>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={() => setMobCat(true)}
                    >
                      {translateFront("Filter")}
                    </Button>
                  )}
                </Grid>
                <Drawer
                  anchor={"left"}
                  open={mobCat}
                  onClose={() => setMobCat(false)}
                >
                  {medLoading || (!medLoading && catListLoading) ? (
                    <LoadingBox w={"100%"} h={700} />
                  ) : (
                    <CategoryList
                      lang={lang}
                      catList={catList}
                      companyList={companyList}
                      dosageList={dosageList}
                      medList={medList}
                      setMedList={setMedList}
                      filteredMedList={filteredMedList}
                      setFilteredMedList={setFilteredMedList}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      selectedSubCat={selectedSubCat}
                      setSelectedSubCat={setSelectedSubCat}
                      selectedDFList={selectedDFList}
                      setSelectedDFList={setSelectedDFList}
                      selectedBrandList={selectedBrandList}
                      setSelectedBrandList={setSelectedBrandList}
                      filterTags={filterTags}
                      setFilterTags={setFilterTags}
                    />
                  )}
                </Drawer>
              </>
            )}
            {/* Select View Type */}
            {smUp && (
              <>
                <Grid item sm={0.75} md={0.5}>
                  <Typography variant="body2">
                    {translateFront("Filter")}
                  </Typography>
                </Grid>
                <Grid item sm={0.75} md={0.5}>
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={view === 0 ? "Grid View" : "List View"}
                  >
                    <IconButton size="small" onClick={handleView}>
                      {view === 0 ? (
                        <GridViewIcon sx={{ color: "black" }} />
                      ) : (
                        <ListIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            )}

            <Grid item xs={7.5} sm={7} md={8}>
              <FormControl sx={{ m: 1, width: "100%" }} size="small">
                <InputLabel>{translateFront("Sort")}</InputLabel>
                <Select
                  value={filter}
                  label={translateFront("Sort")}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem value="generic-brand">
                    {translateFront("By Generic Name")}
                  </MenuItem>
                  <MenuItem value="brand-name">
                    {translateFront("By Brand Name")}
                  </MenuItem>
                  <MenuItem value="company-name">
                    {translateFront("By Company Name")}
                  </MenuItem>
                  <MenuItem value="popularity">
                    {translateFront("By Popularity")}
                  </MenuItem>
                  {/* <MenuItem value="rating">By Rating</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            {/* Asc Desc Preference */}
            <Grid item xs={2} sm={1} md={2}>
              <Tooltip
                title={revertFilter ? "Ascending" : "Descending"}
                arrow
                placement="right"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    setRevertFilter(!revertFilter);
                    let arr = [];
                    if (filteredMedList.length > 0) {
                      arr = [...filteredMedList];
                      setFilteredMedList([...arr].reverse());
                    } else {
                      arr = [...medList];
                      setMedList([...arr].reverse());
                    }
                  }}
                >
                  {revertFilter ? (
                    <ArrowDownwardIcon sx={{ color: "black" }} />
                  ) : (
                    <ArrowUpwardIcon sx={{ color: "black" }} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            {medLoading ? (
              <Grid item xs={12}>
                <LoadingBox w={"100%"} h={700} />
              </Grid>
            ) : filteredMedList.includes("null") ? (
              <Grid item xs={10}>
                <Alert
                  severity="warning"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {translateFront("No Products Available...")}
                </Alert>
              </Grid>
            ) : filteredMedList.length > 0 ? (
              getUnique(filteredMedList)
                .slice(productViewStart, productViewStart + 24)
                .map((x, i) => (
                  <Grid
                    item
                    key={i}
                    xs={12}
                    sm={view === 0 ? 6 : 24}
                    md={view === 0 ? 4 : 24}
                    xl={view === 0 ? 3 : 24}
                  >
                    <ProductSmall
                      x={x}
                      i={i}
                      view={view}
                      catList={catList}
                      lang={lang}
                    />
                  </Grid>
                ))
            ) : medList.length > 0 ? (
              medList
                .slice(productViewStart, productViewStart + 24)
                .map((x, i) => (
                  <Grid
                    item
                    key={i}
                    xs={12}
                    sm={view === 0 ? 6 : 24}
                    md={view === 0 ? 4 : 24}
                    xl={view === 0 ? 3 : 24}
                  >
                    <ProductSmall
                      x={x}
                      i={i}
                      view={view}
                      catList={catList}
                      lang={lang}
                    />
                  </Grid>
                ))
            ) : (
              <Grid item xs={10}>
                <Alert
                  severity="warning"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {translateFront("No Products Available...")}
                </Alert>
              </Grid>
            )}
            {totalPage >= 1 &&
              !filteredMedList.includes("null") &&
              medList.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      color="primary"
                      onChange={handlePageChange}
                      size={smUp ? "medium" : "small"}
                      hideNextButton={smUp ? false : true}
                      hidePrevButton={smUp ? false : true}
                    />
                  </Stack>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
