import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Grid,
  IconButton,
  Input,
  Typography,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  createFilterOptions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// Icon
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PublishIcon from "@mui/icons-material/Publish";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  createGenCompany,
  editGenCompany,
  getCompanies,
  uploadCompanyLogo,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import { PF } from "../../../../config";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function EditCompanyDialog({
  setEditCompanyDlg,
  editCompanyDlg,
  lang,
  companyListEn,
  editCompanyBuffer,
}) {
  // UseState
  const [loading, setLoading] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [uploadedCompanyLogo, setUploadedCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDetectorId, setCompanyDetectorId] = useState("");
  const [companyIsVisible, setCompanyIsVisible] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [alert, setAlert] = useState("");

  // localData
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { editGenCompanyResponse } = useSelector((state) => state.medicine);

  // UseEffect
  // set edit data
  useEffect(() => {
    if (editCompanyBuffer && editCompanyBuffer.companyName) {
      setCompanyName(editCompanyBuffer.companyName);
      setCompanyLogo(editCompanyBuffer.companyLogo);
      setCompanyIsVisible(editCompanyBuffer.isVisible);
      const C = companyListEn.find(
        (c) => c.fixedCompanyId === editCompanyBuffer.companyDetectorId
      );
      C && setCompanyDetectorId(C.fixedCompanyId);
    }
  }, [companyListEn, editCompanyBuffer, editCompanyDlg]);

  useEffect(() => {
    companyName === "" ||
    companyLogo === "" ||
    (lang !== "en" && companyDetectorId === "")
      ? setDisableSubmit(true)
      : setDisableSubmit(false);
  }, [companyDetectorId, companyLogo, companyName, lang]);

  // Check Response
  useEffect(() => {
    if (
      editGenCompanyResponse &&
      editGenCompanyResponse.failed &&
      submitClicked
    ) {
      setAlert(editGenCompanyResponse.message);
      setLoading(false);
    } else {
      setAlert("");
      setLoading(false);
      setEditCompanyDlg(false);
      setCompanyName("");
      setCompanyLogo("");
      setCompanyDetectorId("");
      setUploadedCompanyLogo("");
    }
  }, [editGenCompanyResponse, submitClicked]);

  // Functions
  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    setAlert("");
  };

  const handleAddCompanyDlgClose = () => {
    setEditCompanyDlg(false);
    setAlert("");
    setCompanyName("");
    setCompanyLogo("");
    setCompanyDetectorId("");
    setUploadedCompanyLogo("");
  };

  const dispatch = useDispatch();
  const handleEditCompany = () => {
    setLoading(true);
    setSubmitClicked(true);
    const f = {
      companyName,
      companyDetectorId,
      isVisible: companyIsVisible,
      fixedCompanyId: editCompanyBuffer.fixedCompanyId,
      lang,
      creatorId: localAdminData._id,
      id: editCompanyBuffer._id,
      prevCompanyLogo: companyLogo,
    };
    if (uploadedCompanyLogo) {
      const data = new FormData();
      const ext = uploadedCompanyLogo.name.split(".");
      const filename =
        Date.now() +
        "-" +
        companyName.replace(/\s+/g, "") +
        "-company-logo." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("companyLogo", uploadedCompanyLogo);
      f.uploadedCompanyLogo = filename;
      dispatch(uploadCompanyLogo(data));
    }
    dispatch(editGenCompany(f));
    setTimeout(() => {
      dispatch(getCompanies());
      dispatch(getNotification());
    }, 2000);
  };

  // English Variant
  const handleEnglishVariant = (e, newValue) => {
    setCompanyDetectorId(newValue.fixedCompanyId);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const title1 = "Add Sub Category Dialog";
  const title2 = "Misc Words";
  const title3 = "Manage Generic Company";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate
  return (
    <Dialog
      open={editCompanyDlg}
      onClose={() => setEditCompanyDlg(false)}
      aria-labelledby={"Add-company-dialog"}
    >
      <DialogTitle>{translate3("Edit Company")}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={0}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <LazyLoadImage
              effect="blur"
              src={
                uploadedCompanyLogo !== ""
                  ? URL.createObjectURL(uploadedCompanyLogo)
                  : companyLogo !== ""
                  ? PF + "adminPanel/companies/" + companyLogo
                  : "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/product-label-sticker-design-template-bffa80547b53595c32068e8dbafe0690_screen.jpg?ts=1608744172"
              }
              alt="Halloween party"
              style={{
                objectFit: "cover",
                width: "200px",
              }}
            />
            <Typography variant="body2" align="center" color="secondary">
              {translate1("Recommended")}: 🖼️(800x600)
            </Typography>
          </Grid>
          <Grid item position="relative">
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*"
                id="icon-button-file"
                required
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => setUploadedCompanyLogo(e.target.files[0])}
              />
              <IconButton
                color="primary"
                aria-label="upload-picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {uploadedCompanyLogo && (
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => setUploadedCompanyLogo("")}
                fullWidth
              >
                Cancel Change
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate1("Company Name")}
              fullWidth
              value={companyName}
              onChange={handleCompanyNameChange}
              variant="standard"
              error={alert !== "" ? true : false}
              helperText={alert !== "" && alert}
            />
          </Grid>
          <Grid item xs={12}>
            {lang !== "en" && (
              <>
                <Autocomplete
                  onChange={(e, newValue) => handleEnglishVariant(e, newValue)}
                  disablePortal
                  options={companyListEn}
                  defaultValue={companyListEn.find(
                    (c) =>
                      c.fixedCompanyId === editCompanyBuffer.companyDetectorId
                  )}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.companyName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        src={
                          option.companyLogo.includes("http")
                            ? option.companyLogo
                            : PF + "adminPanel/companies/" + option.companyLogo
                        }
                        alt="admins"
                        style={{
                          objectFit: "contain",
                          width: "40px",
                          height: "30px",
                        }}
                      />
                      {option.companyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("English Variant")}
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={companyIsVisible}
                  onChange={(e) => setCompanyIsVisible(e.target.checked)}
                  color="success"
                />
              }
              label="Show In Front-end"
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              onClick={handleEditCompany}
              endIcon={<PublishIcon />}
              loading={loading}
              loadingPosition="end"
              disabled={disableSubmit}
              variant="contained"
              size="small"
              type="submit"
              color="warning"
            >
              {translate2("Update")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddCompanyDlgClose} color="error">
          {translate1("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
